import React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../app/layout"

import { Row, Col, Breadcrumb } from "antd"

import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"
import LinkUnduhan from "../../components/LinkUnduhan"
import NavigasiNews from "../../components/NavigasiNews"
import { useI18next } from "gatsby-plugin-react-i18next"

const KebijakanRisprosUmum = ({
  data: {
    researchPublicPolicy: data = {
      content: {
        items: [],
      },
    },
  },
}) => {
  const { t } = useI18next()
  return (
    <Layout module_name="research" page_name="research-public-policy">
      <Seo
        title={data.content.submenu_name}
        module_name="research"
        page_name="research-public-policy"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <GatsbyLink to="/">{t("Beranda")}</GatsbyLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.content.submenu_name}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium">
        <Container>
          <Row justify="space-between">
            <Col span={24} md={{ order: 1, span: 17 }}>
              <h1 className="section-title-huge">{data.content.page_title}</h1>
              <article className="article">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.content.page_description,
                  }}
                />
                {data.content.items.map((d, i) => {
                  return (
                    <div key={i}>
                      <h4 id={"anchor-" + i}>{d.content_title}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: d.content_description,
                        }}
                      />
                    </div>
                  )
                })}
              </article>
            </Col>
            <Col span={24} md={6}>
              <div offsetTop={80} className="sticky-sidebar">
                <div className="hide-mobile">
                  <NavigasiNews data={data.content.items} limit={10} />
                  <hr style={{ margin: "30px 0" }} />
                </div>
                <LinkUnduhan Links={data.file} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default KebijakanRisprosUmum

export const query = graphql`
  query researchPublicPolicyQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    researchPublicPolicy(lang: { eq: $language }) {
      content {
        page_description
        page_title
        submenu_name
        items {
          content_description
          content_title
        }
      }
      file {
        header_title
        id
        items {
          file
          item_title
          file_header_id
        }
      }
    }
  }
`
